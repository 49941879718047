<template>
    <div>
        <BaseDrawer />
        <NewsDrawer />
    </div>
</template>

<script>
import BaseDrawer from './components/BaseDrawer/index.vue'
import NewsDrawer from './components/NewsDrawer/index.vue'
export default {
    components: {
        BaseDrawer,
        NewsDrawer
    }
}
</script>