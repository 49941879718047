<template>
    <a-drawer
        title=""
        placement="right"
        :width="isMobile ? '100%' : 700"
        class="news_drawer"
        :visible="visible"
        :zIndex="9999"
        :after-visible-change="afterVisibleChange"
        @close="visible = false">
        <component 
            :is="drawerComponent" 
            :drawerClose="drawerClose" />
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    computed: {
        drawerComponent() {
            if(this.showContent) {
                if(!this.initShow)
                    return null
                else
                    return () => import('./NewsList.vue')
            } else
                return null
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            visible: false,
            showContent: false,
            initShow: true
        }
    },
    methods: {
        drawerClose() {
            this.visible = false
        },
        afterVisibleChange(vis) {
            if(!vis) {
                const query = {...this.$route.query}
                if(query.portal_news) {
                    delete query.portal_news
                    this.$router.push({ query })
                } 
                this.initShow = true
            } else {
                
            }
            this.showContent = vis
        }
    },
    mounted() {
        eventBus.$on('open_portal_news', () => {
            this.visible = true
        })
    },
    beforeDestroy() {
        eventBus.$off('open_portal_news')
    }
}
</script>

<style lang="scss" scoped>
.news_drawer{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .ant-drawer-header-no-title{
            display: none;
        }
        .ant-drawer-body{
            height: 100%;
            padding: 0px;
            background: #eff2f5;
            @media (max-width: 768px) {
                overflow-y: auto;
            }
        }
    }    
}
</style>